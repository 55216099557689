<template>
  <div>
    <div
      data-aos="fade-up"
      data-aos-duration="500"
      data-aos-easing="ease-in-out"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <div class="mt-2">
        <div class="container">
          <h1 class="leistungen-title">Über uns</h1>
          <div class="mt-5">
            <div class="text-center">
              <h2>Über IMMER Gartenbau</h2>
              <p class="mt-3">
                Ob Gartenbau, privates Klein- oder öffentliches Projekt oder
                <br />
                repräsentative Außenanlage: Wir realisieren Ihre Pläne und
                Wünsche rund um Ihren Garten.
              </p>
              <p>
                Unsere Dienstleistung für Sie verbinden wir stets mit fundierter
                Planung und Sorgfalt bei der Realisation. <br />
                Unser Team arbeitet jederzeit zuverlässig und termingerecht.
                Unsere Einsatzregion erstreckt sich über den NRW. <br />
              </p>
              <p>
                Jahrelange Erfahrung in der Bau- und Gartenbranche gewährt Ihnen
                qualitativ hochwertige <br />
                Arbeiten an Ihrem Haus und in Ihrem Garten.
              </p>
            </div>

            <div class="highlight-items">
              <div class="highlight-items-text">
                <div
                  data-aos="fade-right"
                  data-aos-duration="1800"
                  data-aos-once="true"
                >
                  <h5 class="highlight-text-body">
                    <h1 class="highlight-text-title">
                      Was uns in Gartenbau auszeichnet:
                    </h1>
                    <hr />

                    <div class="auszeichne-list">
                      <ul>
                        <li>
                          <span
                            ><svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                              /></svg></span
                          >Fachkundige Beratung
                        </li>
                        <li>
                          <span
                            ><svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                              /></svg></span
                          >Kreative Ideen
                        </li>
                        <li>
                          <span
                            ><svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                              /></svg></span
                          >Sorgfältige Gartenplanung und -realisation
                        </li>
                        <li>
                          <span
                            ><svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                              /></svg></span
                          >Gewissenhafte Organisation des Bauprozesses
                        </li>
                        <li>
                          <span
                            ><svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                              /></svg></span
                          >Hochwertige Gartengestaltung
                        </li>
                        <li>
                          <span
                            ><svg
                              style="width: 24px; height: 24px"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="currentColor"
                                d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                              /></svg></span
                          >Zuverlässige Gartenpflege
                        </li>
                      </ul>
                    </div>
                  </h5>
                </div>
              </div>

              <div
                data-aos="fade-left"
                data-aos-duration="1800"
                data-aos-once="true"
                class="highlight-image"
              >
                <img alt="logo" src="../assets/images/uber-uns.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
