<template>
  <div>
    <h3 class="leistungen-title">Datenschutzerklärung</h3>
    <div class="other-body">
      <h4>1. Verantwortliche Stelle, und Rechte des Betroffenen</h4>
      <p>
        Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer
        personenbezogenen Daten im Sinne des Bundesdatenschutzgesetzes ist die
        IMMER GartenBau. Die IMMER GartenBau ist als verantwortliche Stelle
        unter den nachfolgend genannten Kontaktdaten für Sie erreichbar:
      </p>
      <h5>
        <p>
          IMMER GartenBau <br />
          Finanzstrasse 14, <br />
          46145 Oberhausen <br />
        </p>
      </h5>

      <p>
        Sie können jederzeit unentgeltlich Auskunft über Ihre bei uns
        gespeicherten Daten erhalten, sowie Ihr Recht auf Berichtigung Sperrung
        oder Löschung Ihrer Daten geltend machen. Wenden sie sich hierzu an die
        zuvor genannten Kontaktdaten. Sofern Sie der Erhebung, Verarbeitung oder
        Nutzung Ihrer Daten durch die IMMER GartenBau nach Massgabe dieser
        Datenschutzbestimmungen insgesamt oder für einzelne Massnahmen
        widersprechen wollen, können Sie Ihren Widerspruch per E-Mail oder Brief
        ebenfalls an die zuvor genannten Kontaktdatenrichten.
      </p>
      <h4>2. Nutzungsbedingungen</h4>
      <p>
        Diese Webseite wird von IMMER GartenBau, betrieben. Die auf dieser
        Webseite abrufbaren Inhalte dienen nur der Information. Jegliche Haftung
        für allfällige Schäden, die aus dem Gebrauch der Webseite oder von
        darauf abrufbaren Informationen entstehen, wird ausgeschlossen.
      </p>
      <p>
        Insbesondere wird keine Haftung für die Aktualität, Vollständigkeit und
        Richtigkeit der Inhalte übernommen. Auch die Haftung für Links auf
        Webseiten Dritter und den Inhalt solcher Webseiten ist ausgeschlossen.
        Es kann vorkommen, dass E-Mails nicht oder nicht rechtzeitig zugestellt
        werden. Wir bitten Sie daher, uns zeitkritische oder wichtige
        Mitteilungen mit regulärer Post (mit Empfangsnachweis) oder per Kurier
        zu schicken. Bei der Verwendung von E-Mails empfehlen wir Ihnen, vom
        Empfänger eine Empfangsbestätigung zu verlangen.
      </p>
      <p>
        Im Zweifelsfall sollten Sie den beabsichtigen Empfänger telefonisch
        kontaktieren. Sofern nicht anderweitig vermerkt, liegen die Rechte an
        sämtlichen Inhalten (Bildern, Texten, Marken, etc.), die auf dieser
        Webseite verwendet werden, bei der IMMER GartenBau. Deren Verwendung
        ohne die ausdrückliche vorgängige Zustimmung von IMMER GartenBau ist
        untersagt. Auch das automatisierte vollständige oder teilweise Abrufen
        oder Darstellen von Inhalten dieser Webseite mittels technischer
        Hilfsmittel (wie Webcrawler-/Spider-Programme, Metasuchmaschinen,
        Framing) ist untersagt.
      </p>
      <h4>3. Zweck der Datenschutzerklärung</h4>
      <p>
        3.1. IMMER GartenBau ist Betreiberin der Plattform
        www.immergartenbau.com, über die sie für Kunden Web- und
        Online-Dienstleistungen erbringt. Der Datenschutz ist IMMER GartenBau
        wichtig. IMMER GartenBau hält sich an die geltenden
        datenschutzrechtlichen Vorgaben und Regelungen.
      </p>

      <p>
        3.2. Diese Datenschutzerklärung zeigt den Umgang von IMMER GartenBau mit
        Personendaten auf und legt insbesondere dar, welche Personendaten von
        IMMER GartenBau bearbeitet werden und zu welchen Zwecken die Bearbeitung
        dieser Daten erfolgt. Sie beschreibt zudem, wie gesammelte Personendaten
        überprüft, korrigiert oder gelöscht werden können.
      </p>

      <p>
        3.3. Diese Datenschutzerklärung erstreckt sich nicht auf allfällige
        externe Links, die zu Angeboten Dritter führen. Für diese Angebote
        gelten die Datenschutzbestimmungen des jeweiligen Anbieters.
      </p>

      <p>
        3.4. Diese Datenschutzerklärung ist integrierter Bestandteil der
        Allgemeinen Geschäftsbedingungen von IMMER GartenBau.
      </p>

      <h4>4. Personendaten</h4>
      <p>
        IMMER GartenBau respektiert die Privatsphäre ihrer Kunden und sammelt
        nur Personendaten (wie Name, Adresse, E-Mail-Adresse), wenn diese:
      </p>
      <p>
        vom Kunden freiwillig zur Verfügung gestellt (insb.: Vorname, Nachname,
        Adresse, Mobile-Nummer, Email-Adresse oder MwSt.-Nummer); oder durch
        Cookies automatisch erhoben werden.
      </p>
      <h4>5. Verwendung von Personendaten</h4>
      <p>
        5.1. Vom Kunden an IMMER GartenBau übermittelte Daten dürfen von IMMER
        GartenBau wie folgt verwendet werden:
      </p>
      <p>
        Zur Erstellung eines Kunden-Kontos und zur sonstigen Erfüllung von
        vertraglichen Verpflichtungen; <br />
        Zu Marketingzwecken, also insbesondere um die Bedürfnisse der Kunden
        besser zu verstehen und die Dienste von IMMER GartenBau zu verbessern;
        <br />
        Um Geschäfte mit dem Kunden zu tätigen. Zu diesem Zweck darf IMMER
        GartenBau die ihr vom Kunden übermittelten Daten auch an Vertriebs- und
        Kooperationspartner von IMMER GartenBau im In- und Ausland senden.
        Weitere Personen erhalten von den Personendaten keine Kenntnis. <br />
      </p>
      <p>
        5.2. Der Zugang zu Personendaten ist auf diejenigen Mitarbeiter sowie
        Vertriebs- und Kooperationspartner begrenzt, die diese Daten kennen
        müssen. Die betreffenden Personen und Unternehmen sind zur
        Verschwiegenheit und zur Einhaltung der geltenden Datenschutzgesetze
        verpflichtet.
      </p>
      <p>
        IMMER GartenBau wird Personendaten keinesfalls an Dritte verkaufen oder
        auf andere Weise vermarkten.
      </p>
      <h4>6. Datenschutz</h4>
      <p>
        Mit dem Zugriff auf diese Webseite erklären Sie sich damit
        einverstanden, dass Ihre Daten gemäss dieser Datenschutzrichtlinie
        bearbeitet werden. <br /><br />

        Auf verschiedenen Seiten verwenden wir (IMMER GartenBau) Cookies, um den
        Besuch unserer Webseite attraktiver zu gestalten und die Nutzung
        bestimmter Funktionen zu ermöglichen. Bei Cookies handelt es sich um
        kleine Textdateien, die auf Ihrem Rechner abgelegt werden. Die meisten
        der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung
        wieder von Ihrer Festplatte gelöscht (temporäre Cookies). <br /><br />

        Andere Cookies verbleiben auf Ihrem Rechner und ermöglichen uns, Ihren
        Rechner bei Ihrem nächsten Besuch wieder zu erkennen (dauerhafte
        Cookies). Sie können Ihren Browser so einstellen, dass keine Cookies
        angenommen werden, was unter Umständen zu Einschränkungen in der
        Funktionalität der Webseite führen kann. Unsere Webseite benutzt Dienste
        von Drittanbietern, insbesondere Google Analytics, einen
        Webanalysedienst der Google Inc. <br />
        <br />

        Solche Dienste sammeln Informationen und können (auch dauerhafte)
        Cookies verwenden. Die erzeugten Informationen über Ihre Benutzung
        dieser Webseite (einschliesslich Ihrer IP-Adresse) wird an Server
        solcher Anbieter übertragen, dort gespeichert und bearbeitet und kann
        unter Umständen Ihrem Konto bei diesen Anbietern zugeordnet werden. Die
        Anbieter können diese Informationen benutzen, um Ihre Nutzung der
        Webseite auszuwerten, um Reports über die Webseitenaktivitäten für die
        Webseitenbetreiber zusammenzustellen und um weitere mit der
        Webseitennutzung und der Internetnutzung verbundene Dienstleistungen zu
        erbringen.<br />
        <br />

        Auch werden die Anbieter diese Informationen gegebenenfalls an Dritte
        übertragen oder an Orte, an welchen es an einem angemessenen Datenschutz
        fehlt. Der Datenerhebung und -speicherung durch Google Analytics können
        Sie jederzeit mit Wirkung für die Zukunft widersprechen, indem Sie ein
        Browser-Add-on zur Deaktivierung von Google Analytics herunterladen und
        für Ihren Browser installieren. <br />
        <br />

        Das Deaktivierungs-Add-on finden Sie hier:
        http://tools.google.com/dlpage/gaoptout?hl=de Wenn Sie nicht möchten,
        dass die Anbieter die über unseren Webauftritt gesammelten Daten Ihrem
        jeweiligen Benutzer-Konto zuordnen, müssen Sie sich vor Ihrem Besuch
        unserer Webseite von den Konten der jeweiligen Anbieter (insbesondere
        allfälligen Google-Accounts) ausloggen. Durch die auf dieser Webseite
        verwendeten Webanalysedienste (insbesondere Google Analytics) werden
        Daten erhoben und gespeichert, aus denen Nutzungsprofile erstellt
        werden. <br />
        <br />

        Diese Nutzungsprofile dienen der Analyse des Besucherverhaltens und
        werden zur Verbesserung und bedarfsgerechten Gestaltung unserer Webseite
        ausgewertet. <br />
      </p>
      <h4>7. Recht der Nutzer auf Löschung der Daten</h4>
      <p>
        Nutzer haben das Recht, auf Antrag unentgeltlich Auskunft zu erhalten
        über die personenbezogenen Daten, die von uns über sie gespeichert
        wurden. Zusätzlich haben die Nutzer das Recht auf Berichtigung
        unrichtiger Daten, Widerruf von Einwilligungen, Sperrung und Löschung
        ihrer personenbezogenen Daten sowie das Recht, im Fall der Annahme einer
        unrechtmässigen Datenverarbeitung eine Beschwerde bei der zuständigen
        Aufsichtsbehörde einzureichen.<br /><br />

        Die bei uns gespeicherten Daten werden gelöscht, sobald sie für ihre
        Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen. <br />
      </p>
      <h4>8. Sicherheitsmassnahmen</h4>
      <p>
        8.1. IMMER GartenBau sieht Sicherheitsmassnahmen vor, um Verlust,
        Missbrauch oder Änderung von Informationen und Personendaten zu
        verhindern.
      </p>
      <p>
        8.2. Der Kunden ist sich bewusst, dass Übertragungen im Internet nicht
        sicher sind. Sobald IMMER GartenBau die übertragenen Informationen
        erhalten hat, sichert sie diese in angemessener Weise in ihren Systemen.
      </p>
      <h4>9. Anpassung der Datenschutzerklärung</h4>
      <p>
        IMMER GartenBau behält sich das Recht vor, diese Datenschutzerklärung
        jederzeit im Rahmen der gesetzlichen Vorgaben anzupassen.
      </p>
      <h4>10. Haftungsausschluss</h4>
      <p>
        Die auf dieser Webseite abrufbaren Inhalte dienen nur der Information.
        Sie stellen insbesondere keine Rechtsberatung dar. Jegliche Haftung für
        allfällige Schäden, die aus dem Gebrauch der Webseite oder von darauf
        abrufbaren Informationen entstehen, wird ausgeschlossen. <br /><br />

        Insbesondere wird keine Haftung für die Aktualität, Vollständigkeit und
        Richtigkeit der Inhalte übernommen.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
