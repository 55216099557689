<template>
  <div>
    <h3 class="leistungen-title">Allgemeine Geschäftsbedingungen</h3>
    <div class="other-body">
      <h4>1. Geltungsbereich, Definitionen</h4>
      <p>
        Diese Allgemeinen Geschäftsbedingungen (im Folgenden „AGB“ genannt) sind
        Bestandteil eines jeden Vertrags zwischen der IMMER Gartenbau(im
        Folgenden „immergartenbau.com“ genannt) und dem Dienstleister.
      </p>
      <h4>2. Leistungen</h4>
      <p>
        IMMER Gartenbau bietet die folgenden Leistungen an:
      </p>
      <div class="lestungen-list">
        <ul>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Gartengestaltung
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Natursteinmauern
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Platten- und Pflasterarbeiten
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Terrassen, Wege und Plätze
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Hofeinfahrten und Hauseingänge
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Stufen
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Hangbefestigungen
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Neuanlage Rasenfläche
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Dachbegrünungen
          </li>
        </ul>
        <ul>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Bodenregulierung
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Zaunanlagen
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Sichtschutz
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Holzterrassen
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Neuanlage von Gärten
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Gartenpflege
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Teichanlagen
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Instandsetzungen
          </li>
          <li>
            <span
              ><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                /></svg></span
            >Winterdienst
          </li>
        </ul>
      </div>
      <h4>3. Urheberrecht/Nutzungsrecht</h4>
      <p>
        Die Urheberrechte der Inhalte liegen bei immergartenbau.com, soweit
        nicht anders angegeben.
      </p>
      <p>
        Das Herunterladen (Download) von Inhalten ist nur für private Zwecke
        gestattet. Eine kommerzielle Nutzung bedarf der Zustimmung von
        immergartenbau.com.
      </p>
      <h4>4. Datenschutz</h4>
      <p>
        IMMER Gartenbau verpflichtet sich, in Bezug von „immergartenbau.com“ im
        Rahmen der Auftragsabwicklung erhobenen Daten die Vorschriften des
        Gesetzes gegen den unlauteren Wettbewerb und des Gesetzes über den
        Datenschutz einzuhalten. Ergänzend zu diesen AGB gilt in Bezug auf die
        Erhebung und Verwendung von Personendaten die Datenschutzerklärung von
        immergartenbau.com.
      </p>
      <h4>5. Finanzielle Transaktionen</h4>
      <p>
        Finanzielle Transaktionen über unsere Website werden über die sichere
        Zahlungslösung von stripe abgewickelt und erfüllen höchste
        Sicherheitsstandards. Während der Online-Verbindung zwischen Ihnen und
        stripe sind sämtliche Daten verschlüsselt.
      </p>
      <p>
        Die Identifikationselemente und Zahlungsdaten können nicht eingesehen
        werden.
      </p>
      <h4>6. Haftungsbeschränkung</h4>
      <p>
        Die Inhalte unserer Seiten wurden mit der grösstmöglichen Sorgfalt
        erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der
        Inhalte auf www.immergartenbau.com übernimmt IMMER Gartenbaukeine
        Haftung. Ebenso lehnt immergartenbau.com jede Haftung für Schäden ab,
        die sich durch die Benutzung von www.immergartenbau.com ergeben.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
